<script>
export default {
	onLaunch: function () {},
	onShow: function () {},
	onHide: function () {}
};
</script>

<style lang="scss">
/*每个页面公共css */
@import '@/uni_modules/uv-ui-tools/index.scss';
@import '@/static/index.css';
</style>
